import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"
import banner from "../images/home/banner.png"
import rmaxgraph from "../images/home/exponential-growth.svg"
import rmaxgraph2 from "../images/home/logistic-growth.svg"
import rmaxgraph_fr from "../images/home/exponential-growth_fr.svg"
import rmaxgraph2_fr from "../images/home/logistic-growth_fr.svg"
import topic1 from "../images/home/topic-1.jpg"
import topic2 from "../images/home/topic-2.jpg"
import topic3 from "../images/home/topic-3.jpg"
import ImageCard from "../components/imageCard"
import Instructions from "../components/instructions"
import parse from "html-react-parser"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"

// =========================================================================================================
const IndexPage = ({ location }) => {
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()
  // =========================================================================================================
  const pageContent = {
    pageTitle: parse(t("Home_pageTitle")),
    title: parse(t("Home_title")),
    topText: parse(t("Home_topText")),
    images: [
      {
        src: language == "en" ? rmaxgraph : rmaxgraph_fr,
        alt: "",
        caption: parse(t("Home_images[0].caption")),
        sharedCaption: parse(t("Home_images[0].sharedCaption")),
      },
      { src: language == "en" ? rmaxgraph2 : rmaxgraph2_fr,
        alt: "",
        caption: parse(t("Home_images[1].caption"))
      },
      {
        src: topic1,
        alt: "",
        caption: parse(t("Home_images[2].caption")),
        link: "/exponentialgrowth",
      },
      {
        src: topic2,
        alt: "",
        caption: parse(t("Home_images[3].caption")),
        link: "/logisticgrowth",
      },
      {
        src: topic3,
        alt: "",
        caption: parse(t("Home_images[4].caption")),
        link: "/epopulationgrowth",
      },
    ],
    middleText: parse(t("Home_middleText")),
    instructions: parse(t("Home_instructions")),
    botomText: parse(t("Home_botomText")),
  }

  const imageCards = []
  for (var i = 2; i < 5; i++) {
    imageCards.push(
      <ImageCard
        imgLink={pageContent.images[i].link}
        imgSrc={pageContent.images[i].src}
        imgAlt={pageContent.images[i].alt}
        imgTitle={pageContent.images[i].caption}
      />
    )
  }

  // ================================================================================================
  return (
    <Layout>
      <SEO title={pageContent.pageTitle} />
      <div className="homeContent">
        <Row>
          <Col sm="12">
            <h2>{pageContent.title}</h2>
            {pageContent.topText}
          </Col>
        </Row>
        <Row className="centerMe">
          <Col sm="12" md="6">
            {" "}
            <img
              src={pageContent.images[0].src}
              alt={pageContent.images[0].alt}
            />
            <center>
              <p className="bold smText">{pageContent.images[0].caption}</p>
            </center>
          </Col>{" "}
          <Col sm="12" md="6">
            <img
              src={pageContent.images[1].src}
              alt={pageContent.images[1].alt}
            />
            <center>
              <p className="bold smText">{pageContent.images[1].caption}</p>
            </center>
          </Col>
          <p className="xsText">{pageContent.images[0].sharedCaption}</p>
        </Row>
        <Row>
          <Col sm="12" md="12">
            {pageContent.middleText}
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12">
            <Instructions content={pageContent.instructions} />
          </Col>
        </Row>

        {pageContent.bottomText}

        <Row>
          <Col sm="12" md="12" className="text-centerOFF"></Col>
          {imageCards}
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default IndexPage
