import React from "react"
import { Col } from "react-bootstrap"
import { Link } from "gatsby"

const ImageCard = ({ imgLink, imgSrc, imgTitle, imgDetails, imgAlt }) => {
  return (
    <Col sm="6" md="4" lg="4" className="text-center">
      <div className="cardOFF">
        <Link to={imgLink}>
          <img
            src={imgSrc}
            alt={imgAlt}
            style={{ width: "100%" }}
            className="round"
          />
        </Link>
        <div class="container">
          <Link to={imgLink}>
            <h4 className="blueTitle">
              <b>{imgTitle}</b>
            </h4>
          </Link>
          <p>{imgDetails}</p>
        </div>
      </div>
    </Col>
  )
}

export default ImageCard
